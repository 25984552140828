import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	display: boolean;
	title: string;
	event: React.MouseEvent<HTMLElement> | null;
};

export default class TooltipResponsive extends React.Component<IProps> {
	public render(): JSX.Element {
		let elemClasses: (string | undefined)[] = [classes["root"]];
		if (this.props.display) {
			elemClasses.push(classes["show"]);
		}
		return (
			<div className={elemClasses.join(" ")}>
				<div className={classes["shadow"]} />
				<div className={classes["tooltip"]}>
					<div className={classes["root-content"]}>
						<div className={classes["title"]}>{this.props.title}</div>
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}
