import classes from "./classes.module.scss";
type IProps = {
	active: boolean;
	onClick?: () => void;
};
export default function Crumb({ active, onClick }: IProps) {
	let classesToAdd = [classes["crumb"]];
	if (active) {
		classesToAdd.push(classes["active"]);
	}
	if (onClick) {
		classesToAdd.push(classes["clickable"]);
	}

	return (
		<div
			className={classesToAdd.join(" ")}
			onClick={() => {
				if (onClick) onClick();
			}}
		></div>
	);
}

