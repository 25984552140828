import PhaseInfo from "Components/Materials/PhaseInfo";
import ProgressBar from "Components/Materials/ProgressBar";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";
import Config from "Configs";
import React from "react";
import Phase from "Services/Contracts/Classes/Phase";
import Contract from "Stores/Contract";
import classes from "./classes.module.scss";

type IState = {
	phases: Phase[];
	activePhase: number | null;
};
type IProps = {};

export default class Phases extends React.Component<IProps, IState> {
	private removeOnChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			phases: Contract.getInstance().contractData.phases,
			activePhase: Contract.getInstance().contractData.activePhase,
		};
	}

	public render(): JSX.Element {
		return <DefaultTemplate title="Phases">{this.phasesPage()}</DefaultTemplate>;
	}

	private phasesPage(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<div className={classes["progressbars"]}>
					{this.state.phases.map((phase) => (
						<div key={phase.config.index} className={classes["progressbar-container"]}>
							<div className={classes["progressbar-title"]}>{phase.config.name}</div>
							<div className={classes["progressbar"]}>
								<ProgressBar phase={phase} />
							</div>
						</div>
					))}
				</div>
				{this.state.phases.length > 0 && (
					<PhaseInfo
						phase={this.state.phases[(this.state.activePhase ?? Config.getInstance().get().defaultPhase) - 1]!}
					/>
				)}
			</div>
		);
	}

	public componentDidMount() {
		this.removeOnChange = Contract.getInstance().onChange((contract) => {
			this.setState({ phases: contract.phases, activePhase: contract.activePhase });
		});
	}

	public componentWillUnmount() {
		this.removeOnChange();
	}
}
