import React from "react";

import AddressShort from "Components/Elements/AddressShort";
import CopyClipboard from "Components/Elements/CopyClipboard";
import Config from "Configs";
import Contract from "Stores/Contract";
import Wallet from "Stores/Wallet";

import BoxLines from "./BoxLines";

type IState = {
	totalPurchasedTokens: string | null;
};

export type IProps = {};

export default class ContractAddresses extends React.Component<IProps, IState> {
	private removeContractOnChange = () => {};

	private readonly constractInfo = Config.getInstance().get().contracts;

	constructor(props: IProps) {
		super(props);
		this.state = {
			totalPurchasedTokens: null,
		};
	}

	public render(): JSX.Element | null {
		return (
			<BoxLines
				title="Contract address"
				lines={[
					{
						title: "Tokens contract address",
						value: this.getAddressElement(this.constractInfo.icoToken.tokenAddress) ?? "__",
						background: false,
					},
					{
						title: "Sale contract address",
						value: this.getAddressElement(this.constractInfo.saleContractAddress) ?? "__",
						background: false,
					},
					{
						title: "Purchased tokens",
						value: this.state.totalPurchasedTokens ?? "__",
						background: false,
					}
				]}
				actions={[]}
			/>
		);
	}

	public async componentDidMount() {
		this.onContractChange();
		this.removeContractOnChange = Contract.getInstance().onChange(async () => this.onContractChange());
	}

	public componentWillUnmount() {
		this.removeContractOnChange();
	}

	private getAddressElement(value: string | null): JSX.Element | null {
		if (!value) return null;
		return (
			<CopyClipboard value={value!}>
				<AddressShort text={value!} />
			</CopyClipboard>
		);
	}

	private onContractChange() {
		this.updateContractInfoState(Wallet.getInstance().walletData?.userAddress ?? null);
	}

	private async updateContractInfoState(userAddress: string | null) {
		const totalPurchasedTokens = userAddress
			? (await Contract.getInstance().getUserPurchasedTokens(userAddress)).formatUnits()
			: null;
		this.setState({
			totalPurchasedTokens,
		});
	}
}

