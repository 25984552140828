import BurgerCross from "Components/Elements/BurgerCross";
import React from "react";

import TopMenuStatus, {EOpeningState} from "../../../Stores/TopMenuStatus";

type IState = {
	status: EOpeningState;
};
type IProps = {};

export default class MenuButton extends React.Component<IProps, IState> {
	private removeOnSwitch = () => {};
	
	constructor(props: IProps) {
		super(props);
		this.state = {
			status: TopMenuStatus.getInstance().status,
		};
	}

	public render(): JSX.Element {
		return <span onClick={this.toggleMenu}>
			<BurgerCross state={this.state.status === EOpeningState.CLOSED ? "burger":"cross"}/>
		</span>;
	}

	public componentDidMount() {
		this.removeOnSwitch = TopMenuStatus.getInstance().onSwitch((type) => {
			this.updateTopMenuStatus();
		});
	}

	public componentWillUnmount() {
		this.removeOnSwitch();
	}

	public toggleMenu() {
		TopMenuStatus.getInstance().toggle();
	}

	public getStatus() {
		return this.state.status;
	}

	private updateTopMenuStatus() {
		this.setState({
			status: TopMenuStatus.getInstance().status,
		});
	}
}


