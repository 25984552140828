import Button from "Components/Elements/Button";
import GroupButton from "Components/Elements/ButtonGroup";
import Infos from "Components/Elements/Infos";
import TooltipResponsive from "Components/Elements/TooltipResponsive";
import Symbol from "Components/Materials/Symbol";
import React from "react";
import Crumb from "../Crumb";
import classes from "./classes.module.scss";
import GridCard from "./GridCard";

type IRow = {
	phase: JSX.Element;
	tokensSold: string;
	hardCap: string;
	acceptedCrypto: string;
	sellingPrice: string;
	maxAllocation: string;
	vesting: JSX.Element;
	typeOfFundraising: JSX.Element;
	kycLimitDate: string;
};

type IProps = {
	dataRows: IRow[];
};

type IState = {
	phase: number;
	showPopup: boolean;
	event: React.MouseEvent<HTMLElement> | null;
};
export default class PhasesGridMobile extends React.Component<IProps, IState> {
	static defaultProps: IProps = {
		dataRows: [],
	};

	public constructor(props: IProps) {
		super(props);
		this.state = {
			phase: 0,
			showPopup: false,
			event: null,
		};
	}
	public render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<GroupButton>{this.getPhaseButtons()}</GroupButton>
				<div className={classes["phase-container"]}>{this.getPhase(this.props.dataRows[this.state.phase]!)}</div>
				<div className={classes["crumbs-container"]}>
					{this.getCrumbs()}
				</div>
				<TooltipResponsive
					event={this.state.event}
					display={this.state.showPopup as boolean}
					title="Type of fundraising"
				>
					<div className={classes["tooltip-content"]}>
					<div className={classes["tooltip-subtitle"]}>Guaranteed :</div> The investor can buy tokens
						whenever he wishes during the exercise period.
					</div>
					<div className={classes["tooltip-content"]}>
					<div className={classes["tooltip-subtitle"]}>First come first serve : </div> The quantity of
						token is limited: not all investors will be able to buy.
					</div>
				</TooltipResponsive>
			</div>
		);
	}

	private getPhase(phaseData: IRow): JSX.Element {
		return (
			<div className={classes["phase"]}>
				<GridCard title="Tokens sold | Hardcap">
					{phaseData.tokensSold}
					{phaseData.hardCap}
				</GridCard>
				<GridCard title="Accepted crypto.">{phaseData.acceptedCrypto}</GridCard>
				<GridCard title="Max. allocation">{phaseData.maxAllocation}</GridCard>
				<GridCard title="Symbol">
					<Symbol width={16} height={16} />
				</GridCard>
				<GridCard title="Vesting">{phaseData.vesting}</GridCard>
				{this.getFundraisingType(phaseData)}
				{phaseData.kycLimitDate && <GridCard title="KYC deadline">{phaseData.kycLimitDate}</GridCard>}
			</div>
		);
	}

	private getFundraisingType(phaseData: IRow): JSX.Element {
		return (
			<GridCard
				title={
					<div className={classes["fundraising-type"]}>
						Type of fundraising
						<span
							style={{ display: "flex" }}
							onClick={() => this.displayPopup(true)}
							onMouseLeave={() => this.displayPopup(false)}
						>
							<Infos />
						</span>
					</div>
				}
			>
				{phaseData.typeOfFundraising}
			</GridCard>
		);
	}

	private getCrumbs(): JSX.Element[] {
		return this.props.dataRows.map((item, index) => {
			return 	(
				<Crumb active={this.state.phase === index} onClick={() => this.setState({ phase: index })} key={index}/>
			)
		})
	}

	private getPhaseButtons(): JSX.Element[] {
		return this.props.dataRows.map((item, index) => {
			return (
				<Button
					onClick={() => this.setState({ phase: index })}
					sizing="l"
					variant={this.state.phase === index ? "primary" : "ghost"}
					key={index}
				>
					Phase {index + 1}
				</Button>
			);
		});
	}

	private displayPopup(display: boolean): void {
		this.setState({
			showPopup: display,
		});
	}
}

