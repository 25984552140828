export default function Mode() {
	return (
		<span>
			<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="16.0911" cy="16.5" r="15.5" stroke="#6D879F" />
				<path
					d="M11.3274 10.4178L9.69106 8.7831L8.40925 10.0708L10.0365 11.7055L11.3274 10.4178ZM8.81834 15.5868H6.09106V17.4132H8.81834V15.5868ZM17.0002 6.5H15.182V9.19406H17.0002V6.5ZM23.7729 10.0708L22.4911 8.7831L20.8638 10.4178L22.1456 11.7055L23.7729 10.0708ZM20.8547 22.5822L22.482 24.226L23.7638 22.9384L22.1274 21.3037L20.8547 22.5822ZM23.3638 15.5868V17.4132H26.0911V15.5868H23.3638ZM16.0911 11.0205C13.082 11.0205 10.6365 13.4772 10.6365 16.5C10.6365 19.5228 13.082 21.9795 16.0911 21.9795C19.1002 21.9795 21.5456 19.5228 21.5456 16.5C21.5456 13.4772 19.1002 11.0205 16.0911 11.0205ZM15.182 26.5H17.0002V23.8059H15.182V26.5ZM8.40925 22.9292L9.69106 24.2169L11.3183 22.5731L10.0365 21.2854L8.40925 22.9292Z"
					fill="#6D879F"
				/>
			</svg>
		</span>
	);
}
