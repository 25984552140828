export default function Infos() {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.9999 0.399994C4.69847 0.399994 0.399902 4.69857 0.399902 9.99999C0.399902 15.3014 4.69847 19.6 9.9999 19.6C15.3013 19.6 19.5999 15.3014 19.5999 9.99999C19.5999 4.69857 15.3013 0.399994 9.9999 0.399994ZM9.9999 15.5714C9.52633 15.5714 9.14276 15.1879 9.14276 14.7143C9.14276 14.2407 9.52633 13.8571 9.9999 13.8571C10.4735 13.8571 10.857 14.2407 10.857 14.7143C10.857 15.1879 10.4735 15.5714 9.9999 15.5714ZM11.3478 10.8679C11.1537 10.9428 10.9867 11.0745 10.8686 11.2457C10.7505 11.417 10.6867 11.6198 10.6856 11.8279V12.3143C10.6856 12.4086 10.6085 12.4857 10.5142 12.4857H9.48562C9.39133 12.4857 9.31419 12.4086 9.31419 12.3143V11.8536C9.31419 11.3586 9.45776 10.87 9.74062 10.4629C10.017 10.0643 10.4028 9.75999 10.857 9.58642C11.5856 9.30571 12.057 8.69499 12.057 8.02857C12.057 7.08357 11.1335 6.31428 9.9999 6.31428C8.86633 6.31428 7.94276 7.08357 7.94276 8.02857V8.19142C7.94276 8.28571 7.86562 8.36285 7.77133 8.36285H6.74276C6.64847 8.36285 6.57133 8.28571 6.57133 8.19142V8.02857C6.57133 7.18642 6.9399 6.39999 7.60847 5.81499C8.25133 5.25142 9.0999 4.94285 9.9999 4.94285C10.8999 4.94285 11.7485 5.25357 12.3913 5.81499C13.0599 6.39999 13.4285 7.18642 13.4285 8.02857C13.4285 9.26714 12.612 10.3814 11.3478 10.8679Z"
				fill="#C0C2D1"
			/>
		</svg>
	);
}
