import React from "react";

import Button from 'Components/Elements/Button';

import classes from "./classes.module.scss";
import Generic from "./Generic";

export type IProps = {
	title: string;
	lines: { title: string; value: string | JSX.Element, background?: boolean }[];
	actions: {
		title: string;
		onClickButton: () => void;
		disabled: boolean;
		buttonTitle: string
	}[];
};
type IState = {};

export default class BoxLines extends React.Component<IProps, IState> {
	public render(): JSX.Element {
		return (
			<Generic title={this.props.title}>
				<div className={classes["box-container"]}>
					<div className={classes["lines-container"]}>
						{this.props.lines.map((line: IProps["lines"][number], index) => {
							return (
								<div className={classes["line"]} data-background={line.background} key={index}>
									<div className={classes["line-left"]}>{line.title}</div>
									<div className={classes["line-right"]}>{line.value}</div>
								</div>
							);
						})}
					</div>
					{this.props.actions.length > 0 &&
						<div className={classes["actions-container"]}>
							{this.props.actions.map((action: IProps["actions"][number], index) => {
								return (
									<div className={classes["action"]} key={index}>
										<div className={[classes["line-right"], classes["background"]].join(" ")}>{action.title}</div>
										<div className={classes["button-container"]}>
											<Button disabled={action.disabled} onClick={action.onClickButton} sizing="l">{action.buttonTitle}</Button>
										</div>
									</div>
								);
							})}
						</div>
					}
				</div>

			</Generic>
		);
	}
}

