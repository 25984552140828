import React from "react";
import { Link, NavLink } from "react-router-dom";

import Logo from "Components/Materials/Logo";

import WindowStore from "Stores/WindowStore";
import MenuButton from "../MenuButton";
import ConnectWallet from "../ConnectWallet";
import ThemeModeSwitcher from "../ThemeModeSwitcher";
import WalletAddressShort from "../WalletAddressShort.tsx";
import classes from "./classes.module.scss";

type IState = {
	open: 1 | 0 | -1;
};
type IProps = {};
export default class Header extends React.Component<IProps, IState> {
	private onScrollYDirectionChange = () => {};

	constructor(props: IProps) {
		super(props);

		this.state = {
			open: 0,
		};
	}

	public render(): JSX.Element {
		return (
			<header className={classes["root"]} data-open={this.state.open}>
				<div className={classes["content"]}>
					<div className={classes["segments"]}>
						<div className={[classes["segment"], classes["segment-left"]].join(" ")}>
							<Link className={classes["logo"]} to="/">
								<Logo />
							</Link>
						</div>
						<div className={[classes["segment"], classes["segment-middle"]].join(" ")}></div>
						<div className={[classes["segment"], classes["segment-right"]].join(" ")}>
							<NavLink
								className={(navData) =>
									navData.isActive
										? [classes["route"], classes["active-route"]].join(" ")
										: classes["route"]
								}
								to="/"
							>
								ICO information
							</NavLink>
							<NavLink
								className={(navData) =>
									navData.isActive
										? [classes["route"], classes["active-route"]].join(" ")
										: classes["route"]
								}
								to="/phases"
							>
								Phases
							</NavLink>
							<NavLink
								className={(navData) =>
									navData.isActive
										? [classes["route"], classes["active-route"]].join(" ")
										: classes["route"]
								}
								to="/sale-summary"
							>
								Sale summary
							</NavLink>
							<ThemeModeSwitcher />
							<WalletAddressShort />
							<ConnectWallet />
						</div>
						<div className={classes["burger"]}>
							<MenuButton />
						</div>
					</div>
				</div>
			</header>
		);
	}

	public componentDidMount() {
		this.onScrollYDirectionChange = WindowStore.getInstance().onScrollYDirectionChange((scrollYDirection) =>
			this.visibility(scrollYDirection),
		);
	}

	public componentWillUnmount() {
		this.onScrollYDirectionChange();
	}

	private visibility(scrollYDirection: number) {
		let open: IState["open"] = 1;
		if (window.scrollY > 50 && scrollYDirection < 0 && Math.abs(scrollYDirection) > 8) {
			open = -1;
		}

		if (open !== this.state.open) this.setState({ open });
	}
}

