import classes from "./classes.module.scss";
type IProps = {
	state: "burger" | "cross";
};
export default function BurgerCross({ state }: IProps) {
	return (
		<svg
			data-state={state}
			className={classes["burger"]}
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M4 24H28" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M4 16H28" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M4 8H28" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
}
