import { ReactComponent as BnbToken } from "assets/images/logos/bnb_token_symbol.svg";
import AddressShort from "Components/Elements/AddressShort";
import Button from "Components/Elements/Button";
import CopyClipboard from "Components/Elements/CopyClipboard";
import SquareButton from "Components/Elements/SquareButton";
import Config from "Configs";
import React from "react";
import Phase from "Services/Contracts/Classes/Phase";
import EthBigNumber from "Services/Wallet/EthBigNumber";
import Wallet, { IWallet } from "Stores/Wallet";
import ProgressBar from "../ProgressBar";
import TokenSymbol from "../TokenSymbol";
import classes from "./classes.module.scss";

type IProps = {
	phase: Phase;
};

type IState = {
	balance: IWallet["balance"];
	userAddress: IWallet["userAddress"];
	tokenPrice: EthBigNumber | null;
	hardCap: EthBigNumber | null;
	investedAmount: EthBigNumber | null;
	purchasedAmount: EthBigNumber | null;
	amount: string;
	isKyc: boolean;
	isActive: boolean;
	ratio: EthBigNumber | null;
};

export default class PhaseInfo extends React.Component<IProps, IState> {
	private removeWalletOnChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			ratio: null,
			balance: Wallet.getInstance().walletData?.balance ?? null,
			userAddress: Wallet.getInstance().walletData?.userAddress ?? null,
			tokenPrice: null,
			hardCap: null,
			investedAmount: null,
			purchasedAmount: null,
			amount: "",
			isKyc: false,
			isActive: false,
		};
	}

	public render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				{this.getHeaderElement()}
				{this.getInfosElement()}
				{this.getFooterElement()}
			</div>
		);
	}

	public async componentDidMount() {
		this.removeWalletOnChange = Wallet.getInstance().onChange((walletData) => this.walletOnChange(walletData));
		this.fetchData(this.state.userAddress);
	}

	public componentWillUnmount() {
		this.removeWalletOnChange();
	}

	private async walletOnChange(walletData: IWallet | null) {
		const userAddress = walletData?.userAddress;
		this.setState({
			balance: walletData?.balance ?? null,
			userAddress: walletData?.userAddress ?? null,
		});
		this.fetchData(userAddress);
	}

	private getHeaderElement(): JSX.Element {
		const phaseConfig = this.props.phase.config;
		return (
			<>
				<div className={classes["phase-title"]}>{phaseConfig.name}</div>
				<div className={classes["phase-name"]}>{phaseConfig.label}</div>
				<div className={classes["type-fundraising"]}>{phaseConfig.attrs.typeOfFundraising}</div>
				<div className={classes["price-range"]}>
					<div>0%</div>
					<div>100%</div>
				</div>
				<div className={classes["progressbar"]}>
					<ProgressBar phase={this.props.phase} />
				</div>
			</>
		);
	}

	private getInfosElement(): JSX.Element {
		const phaseConfig = this.props.phase.config;
		return (
			<>
				<div className={classes["infos"]}>
					<div className={classes["contract-info"]}>
						<div className={classes["info-title"]}>
							{Config.getInstance().get().contracts.icoToken.symbol} contract address
						</div>
						<CopyClipboard value={Config.getInstance().get().contracts.icoToken.tokenAddress}>
							<AddressShort text={Config.getInstance().get().contracts.icoToken.tokenAddress} />
						</CopyClipboard>
					</div>
					<div className={classes["contract-info"]}>
						<div className={classes["info-title"]}>Sale contract address</div>
						<CopyClipboard value={Config.getInstance().get().contracts.saleContractAddress}>
							<AddressShort text={Config.getInstance().get().contracts.saleContractAddress} />
						</CopyClipboard>
					</div>
					{this.state.isKyc && (
						<div className={classes["contract-info"]}>
							<div className={classes["info-title"]}>Hardcap</div>
							<div className={classes["info-value"]}>
								<TokenSymbol width={18} height={16} marginLeft={4} marginRight={4} />
								{this.state.hardCap?.formatUnits() ?? "--"}
							</div>
						</div>
					)}
					<div className={classes["contract-info"]}>
						<div className={classes["info-title"]}>Vesting</div>
						<div className={classes["info-value"]}>{phaseConfig.attrs.vesting}</div>
					</div>
					{!this.state.isKyc && this.getPrice()}
				</div>
				{this.state.isKyc && (
					<div className={classes["infos"]}>
						{this.getPrice()}
						<div className={classes["contract-info"]}>
							<div className={classes["info-title"]}>Invested amount</div>
							<div className={classes["info-value"]}>
								<BnbToken className={classes["token-symbol"]} />
								{this.state.investedAmount?.formatUnits() ?? "--"}
							</div>
						</div>
						<div className={classes["contract-info"]}>
							<div className={classes["info-title"]}>Purchased amount</div>
							<div className={classes["info-value"]}>
								<TokenSymbol width={18} height={16} marginLeft={4} marginRight={4} />
								{this.state.purchasedAmount?.formatUnits() ?? "--"}
							</div>
						</div>
					</div>
				)}
			</>
		);
	}

	private getFooterElement(): JSX.Element {
		const balance: string | null = this.state.balance?.formatUnits() ?? null;
		return (
			<>
				{this.state.isKyc && this.state.isActive && (
					<>
						<div className={classes["whitelisted-message"]}>You are whitelisted!</div>
						<div className={classes["amount-section"]}>
							<div className={classes["amount-title"]}>Amount</div>
							<div className={classes["amount-container"]}>
								<input
									value={this.state.amount ?? ""}
									className={classes["amount-input"]}
									title="Token Amount"
									placeholder="Enter amount"
									type="number"
									step="any"
									inputMode="decimal"
									pattern="^[0-9]*[.,]?[0-9]{0,18}$"
									autoComplete="off"
									autoCorrect="off"
									size={18}
									minLength={1}
									maxLength={17}
									min={0.00000000000000001}
									max={"999999999999999999"}
									onChange={(e) => {
										if (e.target.value.length < 18) {
											this.setState({
												amount: e.target.value,
											});
										}
									}}
								/>
								<SquareButton className={classes["max-input"]} onClick={() => this.setMaxToInvest()}>
									Max
								</SquareButton>
							</div>
							<div className={classes["balance-container"]}>
								<div className={classes["info-value"]}>
									<div className={classes["info-title"]}>Your balance</div>
									<BnbToken className={classes["token-symbol"]} />
									{balance}
								</div>
							</div>
						</div>
						{this.state.userAddress && this.checkButtonDisabled() && (
							<Button variant="primary" sizing="l" disabled>
								Buy KVT
							</Button>
						)}
						{this.state.userAddress && !this.checkButtonDisabled() && (
							<Button
								variant="primary"
								sizing="l"
								onClick={async () => {
									const tx = await this.props.phase.investInPhase(
										EthBigNumber.from(this.state.amount),
									);
									if (!tx) return;
									await tx.wait();
									this.setState({ amount: "" });
									this.fetchData(this.state.userAddress);
								}}
							>
								Buy KVT
							</Button>
						)}
					</>
				)}
				{!this.state.userAddress && (
					<Button variant="primary" sizing="l" onClick={() => Wallet.getInstance().connect()}>
						Connect Wallet
					</Button>
				)}
				{this.state.userAddress && !this.state.isKyc && (
					<div className={[classes["whitelisted-message"], classes["warning"]].join(" ")}>
						You are not whitelisted!
					</div>
				)}
			</>
		);
	}

	private getPrice() {
		return (
			<div className={classes["contract-info"]}>
				<div className={classes["info-title"]}>Price</div>
				<div className={classes["info-value"]}>
					<BnbToken className={classes["token-symbol"]} />
					{this.state.tokenPrice?.formatUnits() ?? "--"}
				</div>
				{/* <div className={classes["info-title"]}>Ratio</div>
				<div className={classes["info-value"]}>{this.state.ratio?.formatUnits() ?? "--"}</div> */}
			</div>
		);
	}

	private checkButtonDisabled() {
		if (!this.state.amount) return true;
		const amountEthBigNumber = EthBigNumber.from(this.state.amount);
		if (
			!this.state.balance ||
			!this.state.hardCap ||
			!this.state.purchasedAmount ||
			!this.state.tokenPrice ||
			amountEthBigNumber.isZero()
		) {
			return true;
		}

		return (
			!this.state.amount ||
			this.state.hardCap.eq(EthBigNumber.fromZero()) ||
			amountEthBigNumber.gt(
				this.props.phase.getMaxToInvest(
					this.state.hardCap,
					this.state.purchasedAmount,
					this.state.tokenPrice,
					this.state.balance,
				),
			)
		);
	}

	private setMaxToInvest() {
		if (!this.state.balance || !this.state.hardCap || !this.state.purchasedAmount || !this.state.tokenPrice) return;
		this.setState({
			amount: this.props.phase
				.getMaxToInvest(
					this.state.hardCap,
					this.state.purchasedAmount,
					this.state.tokenPrice,
					this.state.balance,
				)
				.formatUnits(),
		});
	}

	private async fetchData(userAddress: string | null | undefined) {
		const ratio = await this.props.phase.getRatio();
		const tokenPrice = await this.props.phase.getTokenPrice(ratio);

		let purchasedAmount = null;
		let investedAmount = null;
		let hardCap: EthBigNumber | null = null;
		let isKyc = false;
		let isActive = false;
		if (userAddress) {
			const ethHardcap = await this.props.phase.getUserHardcap(userAddress);
			hardCap = await this.props.phase.getTokenHardcap(ethHardcap, ratio);
			investedAmount = await this.props.phase.getUserInvestedAmount(userAddress);
			purchasedAmount = await this.props.phase.getUserTokensPurchased(userAddress);
			isKyc = await this.props.phase.getUserIsWhitelisted(userAddress);
			isActive = await this.props.phase.getStatus();
		}
		this.setState({
			ratio,
			tokenPrice,
			hardCap,
			investedAmount,
			purchasedAmount,
			isKyc,
			isActive,
		});
	}
}

