import Button from "Components/Elements/Button";
import Title from "Components/Elements/Title";
import PhasesGridRecap from "Components/Materials/PhasesGridRecap";
import BasePage from "Components/Pages/Base";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";

import classes from "./classes.module.scss";

export default class Home extends BasePage {
	public render(): JSX.Element {
		return (
			<DefaultTemplate title="ICO information">
				<div className={classes["root"]}>
					<Title>ICO information</Title>
					<p className={classes["title-subtext"]}>
						Kryptview is the first Research-to-Earn platform dedicated to Digital Assets. Platform
						contributors can easily post structured scorings on blockchain tokens or review scorings
						published by other community members. <br />
						The KVT is the utility token of the solution. It serves the following use cases:
					</p>
					<ul className={classes["title-subtext"]}>
						<li>
							Access to the platform: users have to lock a certain amount of KVTs to access the platform
							as contributors. This creates a barrier to entry that deters bots and promoters.
						</li>
						<li>
							Rewards: contributors are rewarded every week on the platform for performing actions such as
							posting research, reviewing research or sharing portfolio models.
						</li>
					</ul>
					<p className={classes["title-subtext"]}>
						The total circulating supply of the KVT is finite and set at 300,000,000 tokens. 23,3% will be
						sold throughout the following phases.
					</p>
					<PhasesGridRecap />
					<div className={classes["kyc-button-container"]}>
						<Button
							variant="ghost"
							sizing="l"
							onClick={() => window.open("https://kryptview.synaps.me/signup", "_blank")}
						>
							Pass KYC
						</Button>
					</div>
				</div>
			</DefaultTemplate>
		);
	}
}
