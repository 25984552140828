import React from "react";
import Infos from "Components/Elements/Infos";

import Symbol from "Components/Materials/Symbol";

import classes from "./classes.module.scss";
import Tooltip from "Components/Elements/Tooltip";
type IRow = {
	phase: JSX.Element;
	active: boolean;
	tokensSold: string;
	hardCap: string;
	acceptedCrypto: string;
	sellingPrice: string;
	maxAllocation: string;
	vesting: JSX.Element;
	typeOfFundraising: JSX.Element;
	kycLimitDate: string;
};

type IProps = {
	dataRows: IRow[];
};

type IState = {
	showPopup: boolean;
	event: React.MouseEvent<HTMLElement> | null;
};

export default class PhasesGridDesktop extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = { showPopup: false, event: null };
	}

	public render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<table>
					<thead>
						<tr className={classes["table-header"]}>
							<th />
							<th>Tokens sold | Hard Cap</th>
							<th>Accepted crypto</th>
							<th>Selling price</th>
							<th>Max. allocation</th>
							<th>Symbol</th>
							<th>Vesting</th>
							{this.getTypeDeLeveeElement()}
							<th>KYC deadline</th>
						</tr>
					</thead>
					<tbody>{this.getPhasesElement()}</tbody>
				</table>
			</div>
		);
	}

	private getPhasesElement(): JSX.Element[] {
		return this.props.dataRows.map((row, i) => {
			let elemClasses: (string | undefined)[] = [classes["table-content"]];
			if (row.active) {
				elemClasses.push(classes["current-phase"]);
			}
			return (
				<tr key={i} className={elemClasses.join(" ")}>
					<td className={classes["table-content-phase"]}>{row.phase}</td>
					<td>
						{row.tokensSold}
						{row.hardCap}
					</td>
					<td>{row.acceptedCrypto}</td>
					<td>{row.sellingPrice}</td>
					<td>{row.maxAllocation}</td>
					<td>
						<Symbol width={16} height={16} />
					</td>
					<td>{row.vesting}</td>
					<td>{row.typeOfFundraising}</td>
					<td>{row.kycLimitDate}</td>
				</tr>
			);
		});
	}

	private getTypeDeLeveeElement(): JSX.Element {
		return (
			<th>
				<span style={{ display: "flex" }}>
					<span>Type of fundraising &nbsp;&nbsp;</span>
					<span
						onMouseEnter={(e) => this.togglePopup(e)}
						onMouseLeave={(e) => this.togglePopup(e)}
						onMouseMove={(e) => this.moovePopup(e)}
					>
						<Infos />
					</span>
				</span>
				<Tooltip event={this.state.event} display={this.state.showPopup as boolean} title="Type of fundraising">
					<div className={classes["tooltip-content"]}>
						<div className={classes["tooltip-subtitle"]}>Guaranteed :</div> The investor can buy tokens
						whenever he wishes during the exercise period.
					</div>
					<div className={classes["tooltip-content"]}>
						<div className={classes["tooltip-subtitle"]}>First come first serve : </div> The quantity of
						token is limited: not all investors will be able to buy.
					</div>
				</Tooltip>
			</th>
		);
	}

	public togglePopup(e: React.MouseEvent<HTMLSpanElement>): void {
		this.setState({
			showPopup: !this.state.showPopup,
			event: e,
		});
	}

	private moovePopup(e: React.MouseEvent<HTMLSpanElement>): void {
		this.setState({
			event: e,
		});
	}
}

