import Config from "Configs";
import React from "react";
import EthBigNumber from "Services/Wallet/EthBigNumber";
import Contract from "Stores/Contract";
import BoxLines from "./BoxLines";

type IProps = {
	userAddress: string | null;
};
type IState = {
	tgeDate: string;
	totalLockedTokens: EthBigNumber | null;
	totalAvailableTokens: EthBigNumber | null;
};

export default class MyTokens extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			totalLockedTokens: null,
			totalAvailableTokens: null,
			tgeDate: Config.getInstance().get().tgeDate,
		};
	}

	public render(): JSX.Element {
		return (
			<BoxLines
				title={"My Tokens"}
				lines={[
					{
						title: "TGE Date",
						value: this.state.tgeDate ?? "__",
					},
					{
						title: "Total Locked tokens",
						value: this.state.totalLockedTokens?.formatUnits() ?? "__",
						background: true,
					},
					{
						title: "Total Available tokens",
						value: this.state.totalAvailableTokens?.formatUnits() ?? "__",
						background: true,
					},
				]}
				actions={[]}
			/>
		);
	}

	public componentDidMount() {
		this.updaTokensInfo();
	}

	public componentDidUpdate(prevProps: IProps) {
		if (prevProps.userAddress === this.props.userAddress) return;
		this.updaTokensInfo();
	}

	private async updaTokensInfo(): Promise<void> {
		const userAddress = this.props.userAddress;
		if (!userAddress) {
			this.setState({
				totalLockedTokens: null,
				totalAvailableTokens: null,
			});
			return;
		}
		this.setState({
			totalLockedTokens: await Contract.getInstance().getUserLockedTokens(userAddress),
			totalAvailableTokens: await Contract.getInstance().getUserAvailableTokens(userAddress),
		});
	}
}

