import React from "react";
import { Link } from "react-router-dom";

import TopMenuStatus, { EOpeningState } from "../../../Stores/TopMenuStatus";
import ConnectWallet from "../ConnectWallet";
import ThemeModeSwitcher from "../ThemeModeSwitcher";
import AddressShort from "../WalletAddressShort.tsx";
import classes from "./classes.module.scss";

type IState = {
	status: EOpeningState;
};
type IProps = {};

export default class TopMenu extends React.Component<IProps, IState> {
	private removeOnSwitch = () => {};

	public constructor(props: IProps) {
		super(props);
		this.updateStatus();
		this.state = {
			status: TopMenuStatus.getInstance().status,
		};
	}

	public render(): JSX.Element {
		return (
			<div className={classes["root"]} {...this.state}>
				<div className={classes["shadow"]} onClick={() => TopMenuStatus.getInstance().close()}/>
				<div className={classes["nav"]} {...this.state}>
					<nav className={classes["top-menu-nav"]}>
						<Link to="/">ICO information</Link>
						<Link to="/phases">Phases</Link>
						<Link to="/sale-summary">Sale summary</Link>
						<ThemeModeSwitcher />
						<AddressShort />
						<ConnectWallet />
					</nav>
				</div>
			</div>
		);
	}

	private updateStatus() {
		document.body.setAttribute("top-menu-status", TopMenuStatus.getInstance().status);
		this.setState({
			status: TopMenuStatus.getInstance().status,
		});
	}

	public componentDidMount() {
		this.removeOnSwitch = TopMenuStatus.getInstance().onSwitch((type) => {
			this.updateStatus();
		});
	}

	public componentWillUnmount() {
		this.removeOnSwitch();
	}
}
