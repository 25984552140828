import computerDesktop from "assets/images/comingSoon/computer-desktop.png";
import computerMobile from "assets/images/comingSoon/computer-mobile.png";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";
import BigEllipse from "assets/images/comingSoon/big-ellipse.svg";
import SmallEllipse from "assets/images/comingSoon/small-ellipse.svg";
import Grid from "assets/images/comingSoon/grid.svg";

import BasePage from "../Base";
import classes from "./classes.module.scss";

type IProps = {
	title: string;
	text: string;
};

export default class ComingSoon extends BasePage<IProps> {
	public render(): JSX.Element {
		return (
			<DefaultTemplate title={this.props.title}>
				<div className={classes["root"]}>
					<div className={classes["left-part"]}>
						<img alt={"big ellipse"} className={classes["big-ellipse"]} src={BigEllipse} />
						<img alt={"small ellipse"} className={classes["small-ellipse"]} src={SmallEllipse} />
						<img alt={"grid"} className={classes["grid"]} src={Grid} />
						<img className={classes["computer-image-desktop"]} alt="computer" src={computerDesktop} />
						<img className={classes["computer-image-mobile"]} alt="computer" src={computerMobile} />
					</div>
					<div className={classes["right-part"]}>
						<div className={classes["title"]}>{this.props.title}</div>
						<div className={classes["title2"]}>Coming soon</div>
						<div className={classes["text"]} dangerouslySetInnerHTML={{ __html: this.props.text }} />
					</div>
				</div>
			</DefaultTemplate>
		);
	}
}

