import React from "react";

import PhaseService from "Services/Contracts/Classes/Phase";
import EthBigNumber from "Services/Wallet/EthBigNumber";

import BoxLines from "./BoxLines";

type IProps = {
	phase: PhaseService;
	userAddress: string | null;
};
type IState = {
	title: string | null;
	tokensSold: EthBigNumber | null;
	vesting: string | null;
	tokenPurchased: EthBigNumber | null;
	tgeStatus: boolean;
	vestingStatus: boolean;
	tgeAmount: EthBigNumber | null;
	vestingAmount: EthBigNumber | null;
};

export default class MyTokensCard extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			title: null,
			tokensSold: null,
			vesting: null,
			tokenPurchased: null,
			tgeStatus: false,
			vestingStatus: false,
			tgeAmount: null,
			vestingAmount: null,
		};
	}

	public render(): JSX.Element {
		return (
			<BoxLines
				lines={[
					{
						title: "Tokens sold",
						value: this.state.tokensSold?.formatUnits() ?? "__",
						background: false,
					},
					{
						title: "Vesting",
						value: this.state.vesting ?? "__",
						background: false,
					},
					{
						title: "Purchased tokens",
						value: this.state.tokenPurchased?.formatUnits() ?? "__",
						background: false,
					},
				]}
				actions={[
					{
						title: this.state.tgeAmount?.formatUnits() ?? "__",
						onClickButton: async () => {
							const tx = await this.props.phase.claimTgeTokens();
							if(!tx) return
							await tx.wait()
							this.updatePhaseInfo();
						},
						buttonTitle: "Claim TGE",
						disabled: !this.state.tgeStatus,
					},
					{
						title: this.state.vestingAmount?.formatUnits() ?? "__",
						onClickButton: async () => {
							const tx = await this.props.phase.claimVestedTokens();
							if(!tx) return
							await tx.wait()
							this.updatePhaseInfo();
						},
						buttonTitle: "Claim Vested",
						disabled: !this.state.vestingStatus,
					},
				]}
				title={this.state.title ?? "__"}
			/>
		);
	}

	public componentDidMount() {
		this.updatePhaseInfo();
	}

	public componentDidUpdate(prevProps: IProps) {
		if (prevProps.phase === this.props.phase) return;
		this.updatePhaseInfo();
	}

	private async updatePhaseInfo(): Promise<void> {
		const phase = this.props.phase;
		const userAddress = this.props.userAddress;
		const tgeAmount = userAddress ? await phase.getUserTgeTokens(userAddress) : null;
		const vestingAmount = userAddress ? await phase.getUserVestedTokens(userAddress) : null;
		let tgeStatus = false;
		let vestingStatus = false;
		if (tgeAmount && !tgeAmount.isZero()) {
			tgeStatus = await phase.getTgeStatus();
		}
		if (vestingAmount && !vestingAmount.isZero()) {
			vestingStatus = await phase.getVestingStatus();
		}
		this.setState({
			title: phase.config.name + " : " + phase.config.label,
			tokensSold: await phase.getTokensSold(),
			vesting: phase.config.attrs.vesting,
			tokenPurchased: userAddress ? await phase.getUserTokensPurchased(userAddress) : null,
			tgeAmount: tgeAmount,
			vestingAmount: vestingAmount,
			tgeStatus,
			vestingStatus,
		});
	}
}

