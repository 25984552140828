import React from "react";

import classes from "./classes.module.scss";

type IProps = {
	text: string;
};
type IState = {};

export default class AddressShort extends React.Component<IProps, IState> {
	public render(): JSX.Element {
		return <span title={this.props.text} className={classes["root"]}>{this.formatAddress(this.props.text)}</span>;
	}

	private formatAddress(address: String) {
		let length = address.length;
		return address.substring(0, 6) + "..." + address.substring(length - 4, length);
	}
}
