import React from "react";
import classes from "./classes.module.scss";
type IProps = {
	title: string | JSX.Element;
};

type IState = {};

export default class GridCard extends React.Component<IProps, IState> {
	public render(): JSX.Element {
		return (
			<div className={classes["grid-card-mobile"]}>
				<div className={classes["grid-card-title"]}>{this.props.title}</div>
				<div className={classes["grid-card-content"]}>{this.props.children}</div>
			</div>
		);
	}
}
