import React from "react";

import Button from "Components/Elements/Button";
import Title from "Components/Elements/Title";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";
import PhaseService from "Services/Contracts/Classes/Phase";
import Contract, { IContract } from "Stores/Contract";
import Wallet from "Stores/Wallet";

import ContractAddresses from "./Cards/ContractAddresses";
import MyTokens from "./Cards/MyTokens";
import Phase from "./Cards/Phase";
import classes from "./classes.module.scss";
import SaleContract from "Services/Contracts/Classes/SaleContract";

type IState = {
	phases: PhaseService[];
	saleContract: SaleContract | null;
};
type IProps = {};

export default class SaleSummary extends React.Component<IProps, IState> {
	private removeContractOnChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			phases: [],
			saleContract: Contract.getInstance().contractData.saleContract,
		};
	}

	public render(): JSX.Element {
		return (
			<DefaultTemplate title="Sale summary">
				<div className={classes["root"]}>
					<div className={classes["first-line"]}>
						<Title>Sale summary</Title>
						<span className={[classes["pancake-button"], classes["desktop"]].join(" ")}>
							<Button variant="ghost" sizing="l">
								Go to Pancake Swap
							</Button>
						</span>
					</div>
					<div className={classes["cards-container"]}>
						<div className={classes["cards-half"]}>
							<ContractAddresses />
							<MyTokens
								userAddress={Wallet.getInstance().walletData.userAddress}
							/>
						</div>
						{this.getPhasesElement()}
					</div>
					<div className={[classes["pancake-button"], classes["mobile"]].join(" ")}>
						<Button variant="ghost" sizing="l">
							Go to Pancake Swap
						</Button>
					</div>
				</div>
			</DefaultTemplate>
		);
	}

	public async componentDidMount() {
		this.onContractChange();
		this.removeContractOnChange = Contract.getInstance().onChange(async () => this.onContractChange());
	}

	public componentWillUnmount() {
		this.removeContractOnChange();
	}

	private getPhasesElement(): JSX.Element[] {
		return this.state.phases.map((phase, index) => (
			<Phase key={index} phase={phase} userAddress={Wallet.getInstance().walletData.userAddress} />
		));
	}

	private onContractChange() {
		this.updatePhasesState(Contract.getInstance().contractData);
	}

	private async updatePhasesState(contract: IContract) {
		this.setState({
			phases: contract.phases,
			saleContract: contract.saleContract,
		});
	}
}
