import React from "react";
import classes from "./classes.module.scss";

type IProps = {

};

type IState = {};

export default class GroupButton extends React.Component<IProps, IState> {


	public render(): JSX.Element {
		return (
			<div {...this.props} className={classes["root"]}>
				{this.props.children}
			</div>
		);
	}
}
