import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	title: string;
};
type IState = {};

export default class Generic extends React.Component<IProps, IState> {
	public render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<h3 className={classes["title"]}>{this.props.title}</h3>
				{this.props.children}
			</div>
		);
	}
}

