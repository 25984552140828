import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	display: boolean;
	title: string;
	event: React.MouseEvent<HTMLElement> | null;
};

export default class Tooltip extends React.Component<IProps> {
	public render(): JSX.Element {
		let elemClasses: (string | undefined)[] = [classes["root"]];
		if (this.props.display) {
			elemClasses.push(classes["show"]);
		}
		const mousePositionX = this.props.event?.clientX;
		const mousePositionY = this.props.event?.clientY;
		const right = window.innerWidth - (mousePositionX ?? 0) - 34;
		const bottom = window.innerHeight - (mousePositionY ?? 0) + 20;
		return (
			<div style={{ bottom: bottom + "px", right: right + "px" }} className={elemClasses.join(" ")}>
				<div className={classes["root-content"]}>
					<div className={classes["title"]}>{this.props.title}</div>
					{this.props.children}
				</div>
				<svg
					className={classes["carrot"]}
					width="25"
					height="15"
					viewBox="0 0 25 15"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M12.5 14.4231L1.26091e-06 4.49015e-06L25 6.67572e-06L12.5 14.4231Z" />
				</svg>
			</div>
		);
	}
}
