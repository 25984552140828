import React from "react";

import Mode from "Components/Elements/Mode";
import ThemeMode from "Stores/ThemeMode";

import classes from "./classes.module.scss";

export default class ThemeModeSwitcher extends React.Component {
	public render(): JSX.Element {
		return (
			<div className={classes["root"]} onClick={() => this.toggle()}>
				<Mode />
			</div>
		);
	}

	private toggle(): void {
		ThemeMode.getInstance().toggle();
	}
}
