import React from "react";
import Desktop from "../UserAgent/Desktop";
import Mobile from "../UserAgent/Mobile";
import PhasesGridDesktop from "./Desktop";
import PhasesGridMobile from "./Mobile";

type IProps = {};

type IState = {
	showPopup: boolean;
	event: React.MouseEvent<HTMLElement> | null;
};

type IRow = {
	phase: JSX.Element;
	active: boolean;
	tokensSold: string;
	hardCap: string;
	acceptedCrypto: string;
	sellingPrice: string;
	maxAllocation: string;
	vesting: JSX.Element;
	typeOfFundraising: JSX.Element;
	kycLimitDate: string;
};

export default class PhasesGrid extends React.Component<IProps, IState> {
	private readonly rows: IRow[] = [
		{
			phase: <span>Private Sale</span>,
			active: false,
			tokensSold: "Closed (TBA)",
			hardCap: "",
			acceptedCrypto: "EUR, BNB",
			sellingPrice: "TBA",
			maxAllocation: "N.D",
			vesting: (
				<span>
					15% cliff at 8 months / <br /> Linear vesting over 10 months
				</span>
			),
			typeOfFundraising: <span>OTC / Guaranteed</span>,
			kycLimitDate: "",
		},
		{
			phase: (
				<span>
					Flash Sale &<br /> Early Birds
				</span>
			),
			tokensSold: "TBA",
			hardCap: "",
			active: true,
			acceptedCrypto: "BNB",
			sellingPrice: "TBA",
			maxAllocation: "1,500 USD",
			vesting: <span>70% at TGE / 30% at 6 months</span>,
			typeOfFundraising: (
				<span>
					Whitelisting required <br />
					First come first served
				</span>
			),
			kycLimitDate: "end Q1 2023",
		},
		{
			phase: <span>Beta Sale</span>,
			tokensSold: "TBA",
			hardCap: "",
			active: false,
			acceptedCrypto: "BNB",
			sellingPrice: "TBA",
			maxAllocation: "5,500 USD",
			vesting: <span>70% at TGE / 30% at 6 months</span>,
			typeOfFundraising: (
				<span>
					Participation to Beta
					<br />
					First come first served
				</span>
			),
			kycLimitDate: "end Q1 2023",
		},
		{
			phase: <span>Public Sale</span>,
			tokensSold: "TBA",
			hardCap: "",
			active: true,
			acceptedCrypto: "BNB",
			sellingPrice: "TBA",
			maxAllocation: "2,500 USD",
			vesting: <span>100% at TGE</span>,
			typeOfFundraising: <span>Open</span>,
			kycLimitDate: "end Q1 2023",
		},
	];
	public render(): JSX.Element {
		return (
			<>
				<Desktop>
					<PhasesGridDesktop dataRows={this.rows} />
				</Desktop>
				<Mobile>
					<PhasesGridMobile dataRows={this.rows} />
				</Mobile>
			</>
		);
	}
}
