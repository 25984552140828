import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	onClick?: () => void;
	className?: string;
};

type IState = {};

export default class SquareButton extends React.Component<IProps, IState> {
	public render(): JSX.Element {
		const props = { ...this.props };
		const classNames = [classes["root"]!];
		if (props.className) classNames.push(props.className);

		return (
			<button {...this.props} className={classNames.join(" ")}>
				{this.props.children}
			</button>
		);
	}
}
